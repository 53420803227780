import { defineStore } from 'pinia';
import type { IAPIGrossNetCalculation, IGrossNetCalculatorParams } from '@hokify/common';

export const useGncStore = defineStore('gnc', {
	actions: {
		async sendGnc(
			payload: IGrossNetCalculatorParams & {
				jobField?: string;
				locationUrl: string;
			}
		): Promise<IAPIGrossNetCalculation> {
			return this.$nuxt.$hokFetch('/app-api/jobseeker/gnc', { method: 'POST', body: payload });
		}
	}
});
