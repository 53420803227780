<template>
	<div :class="classes" class="w-full">
		<div
			:class="[styling.includes('box') ? 'px-3 py-5' : 'container justify-center', bannerPadding]"
			class="flex flex-col items-center"
		>
			<p class="text-center bigger text-color-white max-w-4xl">
				<slot name="headline" />
			</p>
			<HokButton
				v-if="hasSlotButton"
				name="cta-button"
				:size="btnsize"
				:color="btncolor"
				:to="enableLink ? (to ? to : btnlink) : ''"
				:font-weight="styling.includes('box') ? 'normal' : 'bold'"
				@click="$emit('cta-click')"
				><slot name="button" />
			</HokButton>
			<slot name="custom" />
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import HokButton from './HokButton.vue';

export default defineComponent({
	name: 'CallToAction',
	components: { HokButton },
	emits: ['cta-click'],
	data() {
		const classes: string[] = [];

		return {
			classes,
			btncolor: '',
			btnlink: '',
			btnsize: '',
			bannerPadding: ''
		};
	},
	computed: {
		hasSlotButton() {
			return !!this.$slots.button;
		}
	},
	created() {
		switch (this.styling) {
			case 'default':
			case 'user-banner':
				this.classes = ['bg-color-coral'];
				this.btnlink = '/app/login';
				this.btncolor = 'white-text';
				this.btnsize = 'medium';
				this.bannerPadding = 'py-6';
				break;
			case 'user-box':
				this.classes = ['bg-color-main', 'rounded-lg'];
				this.btnlink = '/app/login';
				this.btncolor = 'white-text';
				this.btnsize = 'medium';
				break;
			case 'company-banner':
				this.classes = ['bg-color-main-business'];
				this.btnlink = '/business';
				this.btncolor = 'yellow';
				this.btnsize = 'big';
				this.bannerPadding = 'py-8';
				break;
			case 'company-box':
				this.classes = ['bg-color-main-business', 'rounded-lg'];
				this.btnlink = '/business';
				this.btncolor = 'yellow';
				this.btnsize = 'medium';
				break;
			case 'neutral-banner':
				this.classes = ['bg-color-blue-grey'];
				this.btnlink = '/app/login';
				this.btncolor = 'white-text';
				this.btnsize = 'big';
				this.bannerPadding = 'py-8';
				break;
			default:
				break;
		}
	},
	props: {
		to: { type: String, required: false },
		enableLink: { type: Boolean, required: false, default: true },
		styling: {
			type: String,
			default: 'banner',
			validator: (value: string) =>
				['user-banner', 'user-box', 'company-banner', 'company-box', 'neutral-banner'].includes(
					value
				)
		}
	}
});
</script>
