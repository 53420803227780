<template>
	<div
		class="mt-6 text-start shadow-xl p-0 rounded-xl"
		:class="{ 'max-w-3xl container ': !$isMobile.any }"
	>
		<div class="rounded-t-xl text-color-main px-3 py-5 flex font-bold bg-color-grey-lightest">
			<HokIcon name="icon:calculator" color="main" class="mr-4" />
			Brutto-Netto Rechner
		</div>
		<div class="h-fit bg-color-grey-lightest rounded-b-xl pb-4">
			<div>
				<div class="flex items-center pt-6 mx-2" :class="{ 'justify-center': !$isMobile.any }">
					<p
						class="pb-2 border-b-4 border-color-grey-light cursor-pointer"
						:class="[
							{
								'font-bold border-b-4 border-color-main': socialInsuranceGroup === 0
							},
							$isMobile.any ? 'w-1/2 text-center' : 'px-10'
						]"
						@click="socialInsuranceGroup = 0"
					>
						Angestellte:r
					</p>
					<p
						class="pb-2 border-b-4 border-color-grey-light cursor-pointer"
						:class="[
							{ 'font-bold border-b-4 border-color-main': socialInsuranceGroup === 1 },
							$isMobile.any ? 'w-1/2 text-center' : 'px-10'
						]"
						@click="socialInsuranceGroup = 1"
					>
						Arbeiter:in
					</p>
				</div>
				<div
					class="bg-color-white rounded-xl shadow-xl mx-2 pt-6 pb-12 px-6"
					:class="{ 'mb-8': gncCalculation }"
				>
					<div class="mt-6">
						<p class="font-bold mb-0">Gehalt<span class="text-color-purple">*</span></p>
						<div :class="{ 'flex space-x-4 items-start': !$isMobile.any }">
							<div class="flex space-x-2" :class="{ 'w-7/12 mb-4': !$isMobile.any }">
								<div>
									<HokInput
										id="remuneration"
										v-model="internalRemuneration"
										placeholder="z.B. 1500,00"
										required
										type="number"
										name="remuneration"
										class="mb-2 w-2/3 -mt-2"
									/>
								</div>

								<HokSelect
									:value-promise="grossNetPromise"
									:value="defaultGrossNet"
									name="grossOrNet"
									label=" "
									class="pt-2 w-1/3 z-[17]"
									@input="val => setGrossNet(val.type)"
								/>
							</div>
							<ErrorBox v-if="remunerationError && $isMobile.any" class="-mt-2">
								Bitte gib ein gültiges Gehalt ein
							</ErrorBox>

							<div
								class="flex space-x-3"
								:class="[{ 'w-5/12 pt-4': !$isMobile.any }, { 'mb-8': $isMobile.any }]"
							>
								<HokButton
									color="grey"
									outline
									fullwidth="always"
									class="text-color-text"
									size="small"
									:class="
										internalPeriod === 3
											? 'bg-color-main border-color-main text-color-white '
											: 'bg-color-grey-lightest border-color-grey-lightest'
									"
									@click="setPeriod(3)"
								>
									monatlich
								</HokButton>
								<HokButton
									color="grey"
									outline
									fullwidth="always"
									size="small"
									class="text-color-text"
									:class="
										internalPeriod === 0
											? 'bg-color-main border-color-main text-color-white '
											: 'bg-color-grey-lightest border-color-grey-lightest'
									"
									@click="setPeriod(0)"
								>
									jährlich
								</HokButton>
							</div>
						</div>
						<ErrorBox v-if="remunerationError && !$isMobile.any" class="w-1/2 -mt-6">
							Bitte gib ein gültiges Gehalt ein
						</ErrorBox>
						<div>
							<p v-if="!jobFields || jobFields.length > 1" class="font-bold mb-0">
								Beschäftigt als
							</p>
						</div>
						<Autocomplete
							v-if="!jobFields"
							id="experience-title"
							v-model="currentJobField"
							placeholder="Dein Beruf"
							styling="main"
							:value-promise="ontologySuggestion"
							class="mb-8 -mt-2"
							:class="{ 'w-1/2': !$isMobile.any }"
						/>
						<HokSelect
							v-else-if="jobFields && jobFields.length > 1"
							:value-promise="jobFieldsPromise"
							:value="selectedJobField"
							name="jobFields"
							label=" "
							class="pt-4 mb-8 z-[15]"
							:class="{ 'w-1/2': !$isMobile.any }"
							@input="val => setJobFields(val)"
						/>
					</div>
					<div class="flex space-x-4 items-center mb-8">
						<HokSwitch
							id="showMoreInformation"
							v-model="showMoreInformation"
							name="showMoreInformation"
						/>
						<p class="mb-0">Weitere Informationen angeben</p>
					</div>
					<div v-if="showMoreInformation">
						<div :class="{ 'flex space-x-2 mb-8': !$isMobile.any }">
							<div
								class="flex justify-between space-x-2 mb-4"
								:class="!$isMobile.any ? 'w-1/2' : 'mb-8'"
							>
								<div class="w-1/3">
									<p class="mb-0 font-bold">Jahre</p>
									<HokSelect
										:value-promise="yearPromise"
										:value="year"
										name="year"
										label=" "
										class="pt-2"
										@input="val => setYear(val.value)"
									/>
								</div>
								<div class="w-2/3 relative z-[15]">
									<p class="mb-0 font-bold">Bundesland</p>
									<HokSelect
										:value-promise="statePromise"
										name="state"
										label=" "
										class="pt-2"
										@input="val => setState(val)"
									/>
								</div>
							</div>
							<div class="flex space-x-2" :class="!$isMobile.any ? 'w-1/2' : 'mb-8'">
								<div>
									<p class="mb-0 font-bold">Sachbezug</p>
									<HokInput
										id="nonCashRemuneration"
										v-model="nonCashRemuneration"
										placeholder="z.B. 1500,00"
										required
										type="number"
										name="nonCashRemuneration"
										class="mb-2 mr-1 -mt-2"
									/>
								</div>
								<div>
									<p class="mb-0 font-bold">Lst.-Freibetrag</p>
									<HokInput
										id="exemptAmount"
										v-model="exemptAmount"
										placeholder="z.B. 1500,00"
										required
										type="number"
										name="exemptAmount"
										class="mb-2 -mt-2"
									/>
								</div>
							</div>
						</div>
						<div :class="{ 'flex space-x-16': !$isMobile.any }">
							<div class="flex space-x-4 items-center mb-4">
								<HokSwitch id="pensionFund" v-model="pensionFund" name="pensionFund" />
								<p class="mb-0" :class="{ 'mr-12 ': !$isMobile.any }">betriebl. Vorsorge</p>
							</div>
							<div class="flex space-x-4 items-center mb-4">
								<HokSwitch id="avab" v-model="avab" name="avab" />
								<p class="mb-0">
									AVAB / AEAB
									<ToolTip
										icon="icon:info"
										:size="4"
										color="grey-light"
										class="relative"
										window-top
										text="Alleinverdiener- / Alleinerzieherabsetzbetrag. Der Alleinverdienerabsetzbetrag steht nur in Verbindung mit Kindern gemäß § 106 EStG zu."
									/>
								</p>
							</div>
						</div>
						<div :class="{ flex: !$isMobile.any }">
							<div :class="{ 'mr-12 w-1/3 pt-8': !$isMobile.any }">
								<div class="flex space-x-4 items-center mb-4 w-full">
									<HokSwitch
										id="familyBonusPlus"
										v-model="familyBonusPlus"
										name="familyBonusPlus"
									/>
									<p class="mb-0">
										Familienbonus +
										<ToolTip
											icon="icon:info"
											:size="4"
											color="grey-light"
											text="
								Alleinverdiener- / Alleinerzieherabsetzbetrag"
										/>
									</p>
								</div>
								<div v-if="familyBonusPlus" class="flex space-x-4 items-center mb-4">
									<HokSwitch id="splitBonus" v-model="splitBonus" name="splitBonus" />
									<p class="mb-0">
										Geteilter Bonus
										<ToolTip
											icon="icon:info"
											:size="4"
											color="grey-light"
											window-top
											text="Aufteilung des Familienbonus + Familienbeihilfenberechtigte:r und/oder (Ehe-)Partner Familienbeihilfenberechtigte:r und/oder Unterhaltsleistende:r (Unterhaltsabsetzbetrag) Entscheidung ist nur jahresweise möglich."
										/>
									</p>
								</div>
							</div>
							<div v-if="familyBonusPlus" :class="{ 'w-2/3 px-5 pt-2': !$isMobile.any }">
								<div class="flex space-x-2 mb-4">
									<div class="w-1/2">
										<p class="mb-0 font-bold">Kinder bis 17 J.</p>
										<HokSelect
											:value-promise="childPromise"
											:value="childCountBelow18"
											name="childrenBelow18"
											label=" "
											class="pt-2"
											@input="val => (childCountBelow18 = val.value)"
										/>
									</div>
									<div class="w-1/2">
										<p class="mb-0 font-bold">Kinder ab 18 J.</p>
										<HokSelect
											:value-promise="childPromise"
											:value="childCountAbove18"
											name="childrenAbove18"
											label=" "
											class="pt-2"
											@input="val => (childCountAbove18 = val.value)"
										/>
									</div>
								</div>
							</div>
						</div>
						<div class="mb-8" :class="{ flex: !$isMobile.any }">
							<div :class="{ 'mr-12 w-1/3 pt-8': !$isMobile.any }">
								<div class="flex space-x-4 items-center mb-4">
									<HokSwitch id="commuterInfo" v-model="commuterInfo" name="commuterInfo" />
									<p class="mb-0">
										Pendlerpauschale
										<ToolTip
											icon="icon:info"
											:size="4"
											color="grey-light"
											text="
								Der Pendlereuro kommt ab 20 km bzw. bei Nichtzumutbarkeit von öffentlichen Verkehrsmitteln ab 2 km zum Tragen. Die Pendlerpauschale für Pensionisten wird nicht berücksichtigt. Alle Ergebnisse ohne Gewähr. Weitere Informationen unter www.bmf.gv.at/pendlerrechner."
										/>
									</p>
								</div>
								<div v-if="commuterInfo" class="flex space-x-4 items-center mb-4">
									<HokSwitch
										id="publicTransportAcceptable"
										v-model="publicTransportAcceptable"
										name="publicTransportAcceptable"
									/>
									<p class="mb-0">ÖV ist zumutbar</p>
								</div>
							</div>
							<div v-if="commuterInfo" :class="{ 'w-2/3 px-5 pt-2': !$isMobile.any }">
								<div class="flex space-x-2">
									<div class="w-1/2">
										<p class="mb-0 font-bold">Kategorie</p>
										<HokSelect
											:value-promise="commuterPromise"
											:value="daysId.value"
											name="commuterDays"
											label=" "
											class="pt-2"
											@input="val => (daysId = val)"
										/>
									</div>
									<div class="w-1/2">
										<p class="mb-0 font-bold">Km einfach</p>
										<HokInput
											id="distance"
											v-model="distance"
											placeholder="z.B. 40 km"
											required
											type="number"
											name="distance"
											class="mb-2 -mt-2"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div ref="gncResultsRef" class="flex justify-center">
						<HokButton color="main" @click="calculate(true)">Berechnen</HokButton>
					</div>
				</div>
				<div v-if="gncCalculation" class="container">
					<div class="flex items-center mb-8 ml-2">
						<HokSwitch
							id="showIncomeTaxSocialInsurance"
							v-model="showIncomeTaxSocialInsurance"
							name="showIncomeTaxSocialInsurance"
						/>
						<p class="mb-0 ml-4">Lohnsteuer und SV anzeigen</p>
					</div>
					<div :class="{ flex: !$isMobile.any }">
						<div :class="{ 'w-1/2 mr-2': !$isMobile.any }">
							<div
								class="bg-color-main rounded-xl text-color-white px-4 py-5 shadow-xl"
								:class="showExploreJobsCTA && !isPwa ? 'mb-6' : 'mb-4'"
							>
								<p class="mb-2 font-bold">Dein monatliches Gehalt</p>
								<div class="bg-color-white bg-opacity-10 px-4 rounded-xl">
									<p
										class="flex justify-between pt-4 pb-5 border-b border-color-white font-bold mb-0"
									>
										<span>Brutto</span
										><span>{{ gncCalculation.month.gross.toLocaleString() }} €</span>
									</p>
									<p
										v-if="showIncomeTaxSocialInsurance"
										class="flex justify-between border-b border-color-white py-4 mb-0"
									>
										<span>Sozialversicherung</span
										><span>{{ gncCalculation.month.socialInsurance.toLocaleString() }} €</span>
									</p>
									<p
										v-if="showIncomeTaxSocialInsurance"
										class="flex justify-between border-b-2 border-color-white py-4 mb-0"
									>
										<span>Lohnsteuer</span
										><span>{{ gncCalculation.month.incomeTax.toLocaleString() }} €</span>
									</p>
									<p class="flex justify-between pt-5 pb-4 font-bold">
										<span>Netto</span><span>{{ gncCalculation.month.net.toLocaleString() }} €</span>
									</p>
								</div>
							</div>
							<CallToAction
								v-if="showExploreJobsCTA"
								styling="neutral-banner"
								:enable-link="false"
								class="rounded-xl shadow-xl pb-1"
								:class="{ 'mb-5': $isMobile.any }"
								@cta-click="$emit('go-to-jobsearch')"
							>
								<template #headline
									><span class="font-bold mb-0 font-sm block">Zeit für Veränderung?</span>
									<span class="font-sm mb-0">Checke jetzt unsere Jobangebote!</span></template
								>
								<template #button> Jobs entdecken </template>
							</CallToAction>
						</div>
						<div :class="{ 'w-1/2 ml-2': !$isMobile.any }">
							<div class="bg-color-white rounded-xl px-4 py-5 mb-4 shadow-xl">
								<p class="font-bold mb-2">Jahresgehalt</p>
								<div class="px-4 rounded-xl pt-4 pb-5 bg-color-grey-lightest mb-4">
									<p
										class="flex justify-between mb-0 border-b border-color-white pb-5"
										:class="{
											'font-bold': showIncomeTaxSocialInsurance
										}"
									>
										<span>Brutto</span
										><span>
											{{
												internalRemuneration && internalPeriod === 0 && !isNet
													? Number(internalRemuneration)?.toLocaleString()
													: gncCalculation.year.gross.toLocaleString()
											}}€</span
										>
									</p>
									<p
										v-if="showIncomeTaxSocialInsurance"
										class="flex justify-between border-b border-color-white py-4 mb-0"
									>
										<span>Sozialversicherung</span
										><span>{{ gncCalculation.year.socialInsurance.toLocaleString() }} €</span>
									</p>

									<p
										v-if="showIncomeTaxSocialInsurance"
										class="flex justify-between border-b border-color-white py-4 mb-0"
									>
										<span>Lohnsteuer</span
										><span>{{ gncCalculation.year.incomeTax.toLocaleString() }} €</span>
									</p>
									<p
										class="flex justify-between pt-4 mb-0"
										:class="{
											'font-bold': showIncomeTaxSocialInsurance
										}"
									>
										<span>Netto</span
										><span>
											{{
												internalRemuneration && internalPeriod === 0 && isNet
													? Number(internalRemuneration)?.toLocaleString()
													: gncCalculation.year.net.toLocaleString()
											}}
											€</span
										>
									</p>
								</div>
							</div>
							<div class="bg-color-white rounded-xl px-4 py-5 mb-4 shadow-xl">
								<p class="font-bold mb-2">Urlaubsgeld (13. Gehalt)</p>
								<div class="px-4 rounded-xl pt-4 pb-5 bg-color-grey-lightest mb-6">
									<p
										v-if="showIncomeTaxSocialInsurance"
										class="flex justify-between border-b border-color-white py-4 mb-0"
									>
										<span>Sozialversicherung</span
										><span
											>{{ gncCalculation.holidayBonus.socialInsurance.toLocaleString() }} €</span
										>
									</p>

									<p
										v-if="showIncomeTaxSocialInsurance"
										class="flex justify-between border-b border-color-white py-4 mb-0"
									>
										<span>Lohnsteuer</span
										><span>{{ gncCalculation.holidayBonus.incomeTax.toLocaleString() }} €</span>
									</p>
									<p
										class="flex justify-between mb-0"
										:class="{
											'pt-5 font-bold': showIncomeTaxSocialInsurance
										}"
									>
										<span>Netto</span
										><span>{{ gncCalculation.holidayBonus.net.toLocaleString() }} €</span>
									</p>
								</div>
								<p class="font-bold mb-2">Weihnachtsgeld (14. Gehalt)</p>
								<div class="px-4 rounded-xl pt-4 pb-5 bg-color-grey-lightest mb-4">
									<p
										v-if="showIncomeTaxSocialInsurance"
										class="flex justify-between border-b border-color-white py-4 mb-0"
									>
										<span>Sozialversicherung</span
										><span
											>{{ gncCalculation.christmasBonus.socialInsurance.toLocaleString() }} €</span
										>
									</p>

									<p
										v-if="showIncomeTaxSocialInsurance"
										class="flex justify-between border-b border-color-white py-4 mb-0"
									>
										<span>Lohnsteuer</span
										><span>{{ gncCalculation.christmasBonus.incomeTax.toLocaleString() }} €</span>
									</p>
									<p
										class="flex justify-between mb-0"
										:class="{
											'pt-5 font-bold': showIncomeTaxSocialInsurance
										}"
									>
										<span>Netto</span
										><span>{{ gncCalculation.christmasBonus.net.toLocaleString() }} €</span>
									</p>
								</div>
							</div>
							<p class="text-xs mb-0 text-color-grey-light">
								hokify übernimmt keine Gewähr für die Richtigkeit der Angaben
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import type { GNCPeriod, GNCSocialInsuranceGroup, IAPIGrossNetCalculation } from '@hokify/common';
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import HokInput from './HokInput.vue';
import Autocomplete from './Autocomplete.vue';
import HokSelect from './HokSelect.vue';
import HokSwitch from './HokSwitch.vue';
import CallToAction from './CallToAction.vue';
import ToolTip from './ToolTip.vue';
import ErrorBox from './ErrorBox.vue';

function isHTMLElement(element: any): element is HTMLElement {
	return element && element instanceof HTMLElement;
}

export default defineComponent({
	name: 'GrossNetCalculator',
	components: {
		HokInput,
		Autocomplete,
		HokSwitch,
		CallToAction,
		ToolTip,
		ErrorBox,
		HokSelect
	},
	emits: ['update-period', 'update-remuneration', 'send-gnc', 'go-to-jobsearch'],
	data() {
		const scroll = undefined as boolean | undefined;
		const distance = undefined as number | undefined;
		const daysId = { value: 'Keine' } as { id?: number; value: string };
		const exemptAmount = undefined as string | undefined;
		const nonCashRemuneration = undefined as string | undefined;
		const state = undefined as { id: number; value: string } | undefined;
		const remunerationError = undefined as boolean | undefined;
		const socialInsuranceGroup = 0 as GNCSocialInsuranceGroup;
		const gnc = undefined as {} | undefined;

		return {
			gnc,
			remunerationError,
			state,
			nonCashRemuneration,
			exemptAmount,
			distance,
			scroll,
			defaultGrossNet: 'Brutto',
			socialInsuranceGroup,
			grossNet: [
				{ type: false, value: 'Brutto' },
				{ type: true, value: 'Netto' }
			],
			isNet: false,
			currentJobField: '',
			year: new Date().getFullYear().toString(),
			states: [
				{ id: 0, value: 'Tirol' },
				{ id: 1, value: 'Burgenland' },
				{ id: 2, value: 'Kärnten' },
				{ id: 3, value: 'Niederösterreich' },
				{ id: 4, value: 'Oberösterreich' },
				{ id: 5, value: 'Salzburg' },
				{ id: 6, value: 'Steiermark' },
				{ id: 7, value: 'Wien' },
				{ id: 8, value: 'Vorarlberg' }
			],
			showMoreInformation: false,
			showIncomeTaxSocialInsurance: false,
			pensionFund: false,
			avab: false,
			familyBonusPlus: false,
			splitBonus: false,
			childCountBelow18: 'Keine',
			childCountAbove18: 'Keine',
			commuterInfo: false,
			daysId,
			commuterCategories: [
				{ id: 10, value: 'Keine' },
				{ id: 3, value: '1-3 Tage' },
				{ id: 2, value: '4-7 Tage' },
				{ id: 1, value: '8-10 Tage' },
				{ id: 0, value: '11 oder mehr' }
			],
			publicTransportAcceptable: false
		};
	},
	computed: {
		numberOfChildren(): { value: string }[] {
			return this.children();
		},
		internalRemuneration: {
			get(): number | undefined {
				return this.remuneration;
			},
			set(updatedRemuneration) {
				this.$emit('update-remuneration', Number(updatedRemuneration));
			}
		},
		internalPeriod: {
			get(): number | undefined {
				return this.period;
			},
			set(updatedPeriod) {
				this.$emit('update-period', Number(updatedPeriod));
			}
		},
		randomJobFields() {
			if (this.jobFields) {
				const myRandomFields = this.jobFields.slice(0);
				return myRandomFields.sort(() => Math.random() - 0.5);
			}
			return [];
		},
		selectedJobField(): string {
			if (this.randomJobFields?.length) {
				return this.randomJobFields[0].value;
			}
			return '';
		},
		showExploreJobsCTA(): boolean {
			if (this.isPwa) {
				return true;
			}
			return !this.$route.path.startsWith('/job');
		},
		gncResultsRef() {
			return this.$refs.gncResultsRef as HTMLElement;
		}
	},
	methods: {
		calcYears() {
			const max = new Date().getFullYear();
			const min = max - 5;
			const years: { value: string }[] = [];

			for (let i = max; i >= min; i -= 1) {
				const year = i.toString();
				years.push({ value: year });
			}
			return years;
		},
		children() {
			const children: { value: string }[] = [];

			for (let i = 0; i <= 10; i += 1) {
				if (i === 0) {
					children.push({ value: 'Keine' });
				} else {
					children.push({ value: i.toString() });
				}
			}
			return children;
		},
		setGrossNet(val) {
			this.isNet = val;
			this.calculate(false);
		},
		setYear(year) {
			this.year = year;
		},
		setState(state) {
			this.state = state;
		},
		setJobFields(jobField) {
			this.currentJobField = jobField.value;
		},
		async calculate(scroll) {
			this.scroll = scroll;
			let jobField;
			if (this.internalRemuneration && this.internalRemuneration > 0) {
				this.remunerationError = false;

				if (this.currentJobField) {
					jobField = this.currentJobField;
				} else if (this.selectedJobField) {
					jobField = this.selectedJobField;
				} else {
					jobField = '';
				}

				this.gnc = {
					socialInsuranceGroup: this.socialInsuranceGroup,
					remuneration: this.internalRemuneration,
					isNet: this.isNet,
					jobField,
					period: this.internalPeriod,
					year: this.year,
					state: this.state?.id,
					nonCashRemuneration: this.nonCashRemuneration,
					exemptAmount: this.exemptAmount,
					pensionFund: this.pensionFund,
					avab: this.avab,
					familyBonusPlus: {
						childCountBelow18:
							this.childCountBelow18 === 'Keine' ? 0 : Number(this.childCountBelow18),
						childCountAbove18:
							this.childCountAbove18 === 'Keine' ? 0 : Number(this.childCountAbove18),
						splitBonus: this.splitBonus
					},
					commuterInfo: {
						distance: this.distance || 0,
						daysId: this.daysId.value === 'Keine' ? 0 : this.daysId.id,
						publicTransportUnacceptable: !this.publicTransportAcceptable
					},
					jobId: this.jobId
				};
				this.$emit('send-gnc', this.gnc, this.scroll);
			} else {
				this.remunerationError = true;
			}
		},
		setPeriod(period) {
			this.internalPeriod = period;
			this.$nextTick(() => {
				this.calculate(false);
			});
		},
		onGncCalculationChanged() {
			if (this.scroll) {
				const element = this.gncResultsRef;
				if (isHTMLElement(element)) {
					element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
				}
			}
		},
		ontologySuggestion(term) {
			return this.$nuxt?.$valuesStore?.ontologySuggestion(term);
		},
		childPromise() {
			return this.numberOfChildren;
		},
		grossNetPromise() {
			return this.grossNet;
		},
		yearPromise() {
			return this.calcYears();
		},
		jobFieldsPromise() {
			return this.randomJobFields;
		},
		statePromise() {
			return this.states;
		},
		commuterPromise() {
			return this.commuterCategories;
		}
	},
	props: {
		gncCalculation: { type: Object as PropType<IAPIGrossNetCalculation>, required: false },
		isPwa: { type: Boolean, default: false },
		period: { type: Number as PropType<GNCPeriod>, default: 3 },
		remuneration: { type: Number },
		jobFields: { type: Array as PropType<{ value: string }[]>, required: false },
		jobId: { type: String, required: false }
	},
	watch: {
		gncCalculation: [
			{
				handler: 'onGncCalculationChanged'
			}
		]
	}
});
</script>
